<template>
  <v-card>
    <v-card-title>Edit Automation</v-card-title>
    <v-card-text>
      <v-select v-model="editData.menu" :items="menu" label="Menu"></v-select>

      <v-textarea label="Message" v-model="editData.message"></v-textarea>

      <v-select
        v-model="editData.room"
        :items="roomsData"
        item-text="room_name"
        item-value="room_id"
        label="Room"
        multiple
      ></v-select>

      <div class="flex items-center">
        <v-checkbox
          v-model="editData.schedule"
          label="Schedule"
          style="margin-right: 10px"
        ></v-checkbox>
        <template v-if="editData.schedule">
          <v-row>
            <v-menu
              v-model="editData.schedule"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="editData.date_time"
                  label=" Date"
                  readonly
                  v-on="on"
                  style="margin-right: 10px"
                ></v-text-field>
              </template>
              <v-date-picker
                v-model="editData.date_time"
                no-title
              ></v-date-picker>
            </v-menu>
            <v-menu
              v-model="editData.start_time"
              :close-on-content-click="false"
              offset-y
            >
              <template v-slot:activator="{ on }">
                <v-text-field
                  v-model="editData.start_time"
                  label="Start Time"
                  readonly
                  v-on="on"
                ></v-text-field>
              </template>
              <v-time-picker
                v-model="editData.start_time"
                no-title
              ></v-time-picker>
            </v-menu>
          </v-row>
        </template>
      </div>

      <v-checkbox v-model="editData.weekly" label="Weekly"></v-checkbox>
      <template v-if="editData.weekly">
        <v-select
          v-model="editData.selected_week"
          :items="weekOptions"
          item-text="weekName"
          item-value="weekValue"
          label="Select Week"
        ></v-select>
      </template>
    </v-card-text>
    <v-card-actions>
      <v-btn @click="saveAutomation" color="primary">Save</v-btn>
      <v-btn @click="cancelAutomationsEdit" color="error">Cancel</v-btn>
    </v-card-actions>
  </v-card>
</template>

<script>
export default {
  props: ["editData"],

  emits: ["cancelEdit"],

  data() {
    return {
      selectedWeek: null,
      selectedMenu: "",
      weekOptions: [],

      dialogVisible: false,
      selectedRoom: null,
      roomsData: [],
      menu: ["Notice", "Quiz Exam", "Attendance", "Student Billing"],
      schedule: false,
      weekly: false,
      dateTime: null,
      dateMenu: false,
      start_time: null,
      startTimeMenu: false,
      message: "",
      automationData: [],
    };
  },
  mounted() {
    this.loadRoom();
    this.generateWeekOptions();
  },

  methods: {
    generateWeekOptions() {
      const weekOptions = [];
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      for (let i = 0; i < daysOfWeek.length; i++) {
        const weekName = daysOfWeek[i];
        const weekValue = i + 1;

        weekOptions.push({ weekName, weekValue });
      }

      this.weekOptions = weekOptions;
    },
    loadRoom() {
      this.$api.get("/class_rooms/").then((r) => {
        this.roomsData = r.data.results;
      });
    },

    cancelAutomationsEdit() {
      this.$emit("cancelEdit");
    },
    saveAutomation() {
      const postData = {
        menu: this.editData.menu,
        message: this.editData.message,
        room: this.editData.room,
        schedule: this.editData.schedule,
        date_time: this.editData.date_time,
        start_time: this.editData.start_time,
        weekly: this.editData.weekly,
        selected_week: this.selected_week,
      };

      this.$api.put(`/automations/${this.editData.id}/`, postData).then(() => {
        this.$emit("cancelEdit"); 

      });
    },
  },
};
</script>

<style></style>
