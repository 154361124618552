<template>
   <v-card>
        <v-card-title>Create Automation</v-card-title>
        <v-card-text>
          <v-select
            v-model="selectedMenu"
            :items="menu"
            label="Menu"
          ></v-select>
          <v-textarea label="Message"  v-model="message"></v-textarea>
          <v-select
            v-model="selectedRoom"
            :items="roomsData"
            item-text="room_name"
            item-value="room_id"
            label="Room"
            multiple
          ></v-select>
          <div class="flex items-center">
            <v-checkbox
              v-model="schedule"
              label="Schedule"
              style="margin-right: 10px"
            ></v-checkbox>
            <template v-if="schedule">
              <v-row>
              <v-menu
                v-model="dateMenu"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="dateTime"
                    label=" Date"
                    readonly
                    v-on="on"
                    style="margin-right: 10px"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="dateTime" no-title></v-date-picker>
              </v-menu>
              <v-menu
                v-model="startTimeMenu"
                :close-on-content-click="false"
                offset-y
              >
                <template v-slot:activator="{ on }">
                  <v-text-field
                    v-model="start_time"
                    label="Start Time"
                    readonly
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-time-picker v-model="start_time" no-title></v-time-picker>
              </v-menu>
            </v-row>
            </template>
          </div>

          <v-checkbox v-model="weekly" label="Weekly"></v-checkbox>
          <template v-if="weekly">
            <v-select
              v-model="selectedWeek"
              :items="weekOptions"
              item-text="weekName"
              item-value="weekName"
              label="Select Week"
            ></v-select>
          </template>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="saveAutomation" color="primary">Save</v-btn>
          <v-btn @click="cancelAutomation" color="error">Cancel</v-btn>
        </v-card-actions>
      </v-card>
</template>

<script>
export default {
emits: ["cancelAutomation"],
  data () {
    return {
        selectedWeek: null,
      selectedMenu:"",
      weekOptions: [],
     
      dialogVisible: false,
      selectedRoom: null,
      roomsData: [],
      menu: ["Notice", "Quiz Exam", "Attendance","Student Billing"],
      schedule: false,
      weekly: false,
      dateTime: null,
      dateMenu: false,
      start_time: null,
      startTimeMenu: false,
      message:"",
      automationData:[]
    }
  },
  mounted() {
    this.loadRoom();
    this.generateWeekOptions();
  },

  methods: {
    generateWeekOptions() {
      const weekOptions = [];
      const daysOfWeek = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];

      for (let i = 0; i < daysOfWeek.length; i++) {
        const weekName = daysOfWeek[i];
        const weekValue = i + 1;

        weekOptions.push({ weekName, weekValue });
      }

      this.weekOptions = weekOptions;
    },
    loadRoom() {
      this.$api.get("/class_rooms/").then((r) => {
        this.roomsData = r.data.results;
      });
    },

     cancelAutomation() {
      this.$emit("cancelAutomation"); 
    },
    saveAutomation() {
    const postData = {
      menu: this.selectedMenu,
      message: this.message,
      room: this.selectedRoom,
      schedule: this.schedule,
      date_time: this.dateTime,
      start_time:this.start_time,
      weekly: this.weekly,
      selected_week: this.selectedWeek,
    };

    this.$api
      .post('/automations/', postData)
      .then(() => {
        this.cancelAutomation();

        // this.dialogVisible = false;
      })
      
      
  },
  

  
  },

}
</script>

<style>

</style>