<template>
  <v-card>
    <v-toolbar min-width="100%" dark color="primary">
      <v-card-title>All Automation</v-card-title>
      <v-spacer></v-spacer>
      <v-btn @click="openDialog" color="secondary" dark>Create</v-btn>
    </v-toolbar>

    <v-data-table :headers="headers" :items="automationData">
      <template v-slot:item.action="{ item }">
        <v-btn @click="editItem(item)">Edit</v-btn>
      </template>
    </v-data-table>

    <v-dialog v-model="EditdialogVisible" width="50%">
      <edit :editData="selectedItem" @cancelEdit="cancelAutomationsEdit"></edit>
    </v-dialog>

    <v-dialog v-model="dialogVisible" width="50%">
      <create @cancelAutomation="cancelAutomations"></create>
    </v-dialog>
  </v-card>
</template>

<script>
import Create from './Create.vue';
import Edit from './Edit.vue';


export default {
  components: {
    Create,
    Edit
  },
props: ["editData"],
emits:["cancelEdit"],
  data() {
    return {
      headers: [
        { text: "Title", value: "menu" },
        { text: "Message", value: "message" },
        { text: "Action", value: "action" },
      ],
      dialogVisible: false,
      automationData: [],
      selectedItem:[],
      EditdialogVisible:false,
    };
  },

  mounted() {
    this.loadAutomation();
  },

  methods: {
    loadAutomation() {
      this.$api.get("/automations/").then((r) => {
        this.automationData = r.data.results;
      });
    },

    openDialog() {
      this.dialogVisible = true;
    },

    editItem(item) {
      this.selectedItem = item;

      this.EditdialogVisible = true;

    },

    cancelAutomations() {
      this.dialogVisible = false;
      this.loadAutomation();
    },
    cancelAutomationsEdit() {
      this.EditdialogVisible = false;
      this.loadAutomation();
    },
  },
};
</script>

<style>
/* Your style rules */
</style>
